import React from "react"

import styles from "./../../../policies-css-module.module.scss"
import "../../../../styles.scss"

import { Layout } from "../../../../components"

import AugustOffer2024Tnc from "../../../../components/policies/2024/augustOffer2024/august-offer-tnc"
import { Link } from "gatsby"

const AugustOffer = () => {
  return (
    <>
      <Layout>
        <div className={styles.container}>
          <div className={styles.link_section}>
            <div className={styles.link_section_div}>
              <Link
                exact
                className={styles.tc_links}
                activeClassName="is-active"
                to="/promos/august-offer-2024/tnc/"
              >
                AUGUST OFFERS
              </Link>
            </div>
          </div>
          <div className={styles.content_section}>
            <AugustOffer2024Tnc />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default AugustOffer
